import toast from 'react-hot-toast'
import { useAppDispatch } from '../../reducers/store'
import { fetchMembers, postUnblockMember } from '../../reducers/members'
import { IconHourglassHigh } from '@tabler/icons-react'
import { useState } from 'react'
import { SelectOption } from '../../types/DropDownTypes'
import { gracePeriodOptions } from '../../strings/dropdown-options'
import { GracePeriod } from '../../types/MemberTypes'

interface IUnblockMemberModalProps {
  setShowModal: (show: boolean) => void
  memberName: string
  memberId: string
  memberBlockedByPolicy: string
  setShowPopout: (show: boolean) => void
}

function UnblockMemberModal(props: IUnblockMemberModalProps) {
  const { setShowModal, memberName, memberId, memberBlockedByPolicy, setShowPopout } = props
  const di = useAppDispatch()
  const [gracePeriod, setGracePeriod] = useState<string>(GracePeriod.ONE_HOUR)

  const handleUnblockMember = async () => {
    const memberUnblocked = await di(postUnblockMember(memberId, memberBlockedByPolicy, gracePeriod))
    if (!memberUnblocked) {
      toast.error('An error occurred while unblocking the member', { duration: 6000 })
    } else {
      toast.success(`${memberName} will be unblocked. This may take a few minutes`, { duration: 6000 })
    }
    di(fetchMembers())
    setShowPopout(false)
    setShowModal(false)
  }

  return (
    <div className="bg-black bg-opacity-50 fixed top-0 left-0 w-full h-full z-50" onClick={() => setShowModal(false)}>
      <div
        className="fixed top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2 bg-white rounded-lg p-6 max-h-[80%] w-[388px] overflow-y-auto"
        onClick={(e) => e.stopPropagation()}
      >
        <div className="block">
          <p className="text-2xl font-semibold">Unblock User</p>
        </div>
        <p className="mb-4 text-sm text-ErrorRed">
          Please note: if the member has any open alerts these will be closed when the member is unblocked.
        </p>
        <div className="flex justify-between mt-10">
          <div className="flex mt-1.5">
            <IconHourglassHigh size={20} className="mt-0.5" />
            <p className="leading-normal ml-2">Expires after</p>
          </div>
          <select
            className="h-10 w-[180px] flex border-[#00000027] border rounded px-2 py-2.5 justify-between text-sm"
            value={gracePeriod}
            onChange={(e) => setGracePeriod(e.target.value)}
          >
            {gracePeriodOptions.map((option: SelectOption) => {
              return (
                <option key={option.value} value={option.value}>
                  {option.label}
                </option>
              )
            })}
          </select>
        </div>

        <div className="flex justify-end mt-8">
          <button
            className="px-4 py-2 mr-2 bg-gray-300 rounded-lg"
            onClick={() => {
              setShowModal(false)
            }}
          >
            Cancel
          </button>
          <button
            className={`px-4 py-2 bg-PurpleBlue-10 text-white rounded-lg ${gracePeriod === '' ? 'cursor-not-allowed opacity-50' : ''}`}
            onClick={() => {
              handleUnblockMember()
            }}
            disabled={gracePeriod === ''}
          >
            Unblock User
          </button>
        </div>
      </div>
    </div>
  )
}

export default UnblockMemberModal
